import React, { useState } from "react";
import { useGenieDataMutation } from "./hooks";
import {
  Box,
  Button,
  CircularProgress,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { COLORS } from "constants/colors";

const maxChunks = 1;

export const GenieDataForm = () => {
  const [isMutating, setIsMutating] = useState(false);
  const { mutateAsync: addData } = useGenieDataMutation();

  return (
    <Box minWidth="100%">
      <Box px={2} py={2} minWidth="100%">
        <Typography variant="h3">Add Genie Data</Typography>
        <Box
          component="form"
          onSubmit={async (e) => {
            e.preventDefault();
            const citationInput = document.getElementById("citation");
            const textInput = document.getElementById("text");
            const citationValue =
              citationInput instanceof HTMLInputElement
                ? citationInput.value
                : "";
            const textValue =
              textInput instanceof HTMLTextAreaElement ? textInput.value : "";

            if (!citationValue) {
              alert("Please enter a citation");
              return;
            }

            if (!textValue) {
              alert("Please enter text");
              return;
            }

            if (citationValue && textValue) {
              try {
                setIsMutating(true);
                // split by two spaces, and also punctuation
                const splitText = textValue
                  .split("\n")
                  .join(" ")
                  .split(/(?<=[.!?])\s+(?=[A-Z])/)
                  .filter((chunk) => chunk.trim().length > 80);
                const pendingChunks = [];
                for (let i = 0; i < splitText.length; i += maxChunks) {
                  const chunks = splitText
                    .slice(i, i + maxChunks)
                    .filter((chunk) => chunk.trim().length > 80);
                  if (chunks.length === 0) {
                    continue;
                  }
                  pendingChunks.push(
                    addData({
                      citation: citationValue,
                      text: chunks.join("\n"),
                    })
                  );
                }

                await Promise.all(pendingChunks);

                // Clear the form
                citationInput.value = "";
                textInput.value = "";
                alert("Data added successfully");
              } catch (e) {
                console.error(e);
                alert("An error occurred while adding data");
              } finally {
                setIsMutating(false);
              }
            }
          }}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          rowGap={2}
        >
          <Input placeholder="Citation" id="citation" type="text" fullWidth />
          <TextField
            multiline
            inputProps={{
              id: "text",
            }}
            sx={{ mb: 2 }}
            fullWidth
          />
          <Box bgcolor={COLORS.ELECTRICITY} p={2} borderRadius={2}>
            <Typography variant="body2">
              The Genie will take the content in this block and split by line
              break to allow the LLM to get smaller amounts of contextually
              relevant data.
            </Typography>
          </Box>
          {isMutating ? (
            <Box>
              <CircularProgress />
              <Typography variant="body2">
                Adding data to the Genie. This may take a few minutes. Please do
                not refresh the page.
              </Typography>
            </Box>
          ) : null}
          <Button type="submit" variant="contained" disabled={isMutating}>
            Add Genie Data
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
