import { makeApiRequest } from "../components/utils";
import { useQuery } from "@tanstack/react-query";

export const useCertifications = () => {
  return useQuery({
    queryKey: ["certifications"],
    queryFn: () =>
      makeApiRequest({
        endpoint: "/api/certifications",
        method: "GET",
      }),
  });
};
