import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "../components/utils";

export const useCreateVendorCertificationMutation = ({ vendorId }) =>
  useMutation({
    mutationFn: ({ certificationId }) =>
      makeApiRequest({
        endpoint: `/api/vendors/${vendorId}/certifications/${certificationId}`,
        method: "POST",
      }),
  });

export const useUpdateVendorCertificationMutation = ({ vendorId }) =>
  useMutation({
    mutationFn: ({ certificationId, ...fields }) =>
      makeApiRequest({
        endpoint: `/api/vendors/${vendorId}/certifications/${certificationId}`,
        method: "PUT",
        body: fields,
        json: true,
      }),
  });
