import React from "react";
import { Box } from "@mui/material";

/**
 * PageLayout
 * @type {React.FC<React.PropsWithChildren<{ sx?: any }>>}
 */
export const PageLayout = ({ children, sx }) => {
  return (
    <Box mx={2} py={4} zIndex="0" sx={sx}>
      {children}
    </Box>
  );
};
