import React from "react";
import { Box, Typography } from "@mui/material";
import { COLORS } from "constants/colors";

const typeToColor = {
  info: COLORS.OFFSET,
  warn: COLORS.ELECTRICITY,
  error: COLORS.ERROR_BG,
};

export const Callout = ({
  children,
  type,
  maxWidth = "400px",
  ...boxProps
}) => {
  return (
    <Box
      maxWidth={maxWidth}
      sx={{
        borderRadius: 2,
        backgroundColor: typeToColor[type] ?? COLORS.LIGHT_GRAY,
      }}
      padding={2}
      {...boxProps}
    >
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};
