import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { COLORS } from "../../constants/colors";
import { SidebarModuleCheck } from "./SidebarModule";

export const CertificationModule = ({
  id,
  title,
  onChange,
  certificationId,
  certificationURL,
  certificationStatus,
}) => (
  <Box
    key={id}
    sx={{
      mb: 1,
      p: 2,
      borderRadius: 2,
      boxShadow: `inset 0px 0px 0px 1px ${COLORS.OFFSET}`,
    }}
  >
    <Box pb={2}>
      <Typography variant="h3">{title}</Typography>
      <SidebarModuleCheck
        label="Active"
        isChecked={certificationStatus === "active"}
        onChange={() => {
          onChange({
            target: {
              name: `certificationStatus:${certificationId}`,
              value: certificationStatus === "active" ? "expired" : "active",
            },
          });
        }}
        name={`certificationStatus:${certificationId}`}
        hideDivider
      />
    </Box>
    <TextField
      label="Certification URL"
      value={certificationURL}
      sx={{ width: "100%" }}
      name={`certificationURL:${certificationId}`}
      onChange={onChange}
      inputProps={{
        type: "url",
      }}
    />
  </Box>
);
