import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Selector from "./Selector";
import Picklist from "./Picklist";
import { useProductTypeQuery, useProductTypesByBrandQuery, useBrandsQuery, useProductTypeAssociationMutation } from "../hooks";

const nameSort = (a, b) => {
  if (a.name < b.name) { return -1; }
  if (a.name > b.name) { return 1; }
  return 0;
};

const ProductTypesByBrand = () => {

  const [selectedBrand, setSelectedBrand] = useState(null);

  const onSetSelected = (id) => {
    setSelectedBrand(id);
  };

  const { data: productData } = useProductTypeQuery();
  const { data: brandProductData } = useProductTypesByBrandQuery(selectedBrand);
  const { isLoading: brandsQueryPending, error: brandsQueryError, data: brandsData } = useBrandsQuery();
  const { create: associate, delete: dissociate } = useProductTypeAssociationMutation();

  const brands = brandsData?.brands.map((brand) => ({
    id: brand.id,
    name: brand?.Vendor?.vendorName
  })) || [];

  brands.sort(nameSort);
  const associateProductTypeWithBrand = async (id) => {
    await associate.mutateAsync({
      brandId: selectedBrand,
      productTypeId: id
    });
  };

  const dissociateProductTypeFromBrand = async ({ id: productTypeId }) => {
    await dissociate.mutateAsync({ brandId: selectedBrand, productTypeId });
  };

  const productTypes = productData?.productTypes ?? [];
  productTypes.sort((a, b) => a.name.localeCompare(b.name));

  const selectedProductTypes = brandProductData?.productTypes?.map((pbi) => ({
    id: pbi.productTypeId,
    name: pbi.ProductType?.name,
    relationshipId: pbi.id
  })) ?? [];
  selectedProductTypes.sort(nameSort);

  const availableProductTypes = selectedBrand ? productTypes?.filter((productType) => !selectedProductTypes.find((sb) => sb.id === productType.id)).map((productType) => ({
    id: productType?.id,
    name: productType?.name
  })) ?? [] : [];
  availableProductTypes.sort(nameSort);

  return (
    <Box display="flex" flexDirection="row" gap={2}>
      <Box flex={1}>
        {brandsData && brands.length && <Selector
          title="Brands"
          options={brands}
          onSetSelected={onSetSelected}
        />}
        {
          brandsQueryPending && <Typography variant="h6">Loading...</Typography>
        }
        {
          brandsQueryError && <Typography variant="h6">Error: {brandsQueryError.message}</Typography>
        }
      </Box>
      <Box flex={1}>
        <Picklist
          title="Product Types"
          activeList={selectedProductTypes}
          availableList={availableProductTypes}
          onActiveItemClick={dissociateProductTypeFromBrand}
          onAvailableItemClick={associateProductTypeWithBrand}
        />
      </Box>
    </Box>
  );
};

export default ProductTypesByBrand;
