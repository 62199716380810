import React, { useMemo, useState } from "react";

import Box from "@mui/material/Box";
import { Autocomplete } from "@mui/material";
import { COLORS } from "../../constants/colors";
import { TextField } from "@mui/material";
import { useVendors, useVendor } from "../../hooks/useVendors";

export const VendorSelect = ({ value, onChange, name, label, width = 300 }) => {
  const [search, setSearch] = useState();
  const { data: vendorData } = useVendors({
    cleanedOnly: true,
    search,
  });
  const { data: selectedVendorData } = useVendor(value, {
    enabled: !!value,
  });
  const selectedVendor = selectedVendorData?.vendor;
  const allVendors = useMemo(() => {
    if (selectedVendor) {
      return [selectedVendor, ...(vendorData?.vendors ?? [])];
    }
    return vendorData?.vendors ?? [];
  }, [selectedVendor, vendorData]);

  const vendorOptions = useMemo(() => {
    const map = new Map();
    allVendors?.forEach((vendor) => {
      map.set(vendor.id, vendor);
    });

    return (
      Array.from(map.entries())?.map(([vendorId, vendor]) => ({
        label: `${vendor.vendorName} (id: ${vendorId})`,
        value: vendorId,
        ...vendor,
      })) || []
    );
  }, [allVendors]);

  return (
    <Autocomplete
      id="vendor-select"
      options={vendorOptions}
      sx={{ width }}
      filterOptions={(x) => x}
      onInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      value={
        vendorOptions?.find((option) => option.id === value)?.vendorName ?? null
      }
      onChange={(event, newValue) => {
        onChange({
          detail: newValue,
          target: {
            name,
            value: newValue?.id,
          },
        });
      }}
      renderOption={(props, option) => {
        return (
          <Box
            component="li"
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              justifyContent: "flex-start",
              width: "100%",
              alignContent: "flex-start",
              "& > *": {
                width: "100%",
              },
            }}
            {...props}
          >
            <Box as="small" m={0} color={COLORS.GRAY}>
              {option.id}
              {". "}
              {option?.JoroSubcategory?.joroSubcategory ?? ""}
            </Box>
            <Box as="h5" m={0}>
              {option.vendorName}
            </Box>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={label ?? "Vendor"} />
      )}
    />
  );
};
