import React from "react";
import { useRouteError } from "react-router-dom";
import { Box } from "@mui/material";
import { COLORS } from "../constants/colors";
import { A, H2, P } from "./typography";

export function ErrorBoundary() {
  let errorResp = useRouteError();
  console.error(errorResp);
  const error = errorResp?.error;
  // Uncaught ReferenceError: path is not defined
  return (
    <Box
      m={0}
      backgroundColor={COLORS.COMMONS_BLUE}
      width="100vw"
      height="100vh"
      position={"fixed"}
      top={0}
      left={0}
      p={4}
    >
      <H2 color={COLORS.WHITE}>Sorry, something went wrong</H2>
      <P color={COLORS.LIGHT_GRAY}>
        Please try refreshing the page, or{" "}
        <A color={COLORS.OFFSET} href="/">
          restarting
        </A>{" "}
        the application. If the problem persists, please contact a #dev.
      </P>
      <Box
        mt={2}
        color={COLORS.LIGHT_GRAY}
        maxWidth="calc(100vw - 32px)"
        overflow="scroll"
      >
        <pre>
          <code>{error?.stack}</code>
        </pre>
      </Box>
    </Box>
  );
}
