import { makeApiRequest } from "../components/utils";
import { useMutation } from "@tanstack/react-query";

export const useUploadImage = () => {
  return useMutation(async ({ vendorId, image }) => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("bucket", vendorId);
    return makeApiRequest({
      endpoint: "/api/upload",
      method: "POST",
      body: formData,
    });
  });
};
