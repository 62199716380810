/* eslint-disable no-console */
import React from "react";
import { PageLayout } from "./Page";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { makeApiRequest } from "./utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { OLD_APP_URL } from "../constants/potato";

const getAllVendorCategorizationProposals = async () => {
  const data = await makeApiRequest({
    endpoint: "/api/vendorCategorizationProposals",
    method: "GET",
  });
  return data;
};

const getAllVendorMappingProposals = async () => {
  const data = await makeApiRequest({
    endpoint: "/api/vendorMappingProposals",
    method: "GET",
  });
  return data;
};

const updateCategory = async ({ id, status }) => {
  const res = await makeApiRequest({
    endpoint: `/api/vendorCategorizationProposals/${id}`,
    method: "PATCH",
    body: JSON.stringify({ status }),
    additionalHeaders: {
      "Content-Type": "application/json",
    },
  });
  return res;
};

const updateCleanVendor = async ({ id, status }) => {
  const res = await makeApiRequest({
    endpoint: `/api/vendorMappingProposals/${id}`,
    method: "PATCH",
    body: JSON.stringify({ status }),
    additionalHeaders: {
      "Content-Type": "application/json",
    },
  });
  return res;
};

function Proposals() {
  const queryClient = useQueryClient();

  const { data: proposedCategories } = useQuery({
    queryKey: ["getAllVendorCategorizationProposals"],
    queryFn: getAllVendorCategorizationProposals,
  });

  const { data: proposedVendors } = useQuery({
    queryKey: ["getAllVendorMappingProposals"],
    queryFn: getAllVendorMappingProposals,
  });

  const updateCategoryMutation = useMutation({
    mutationFn: updateCategory,
    onError: (error) => window.alert(error),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: "getAllVendorCategorizationProposals",
      }),
  });

  const updateMappingMutation = useMutation({
    mutationFn: updateCleanVendor,
    onError: (error) => window.alert(error),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: "getAllVendorMappingProposals",
      }),
  });
  console.log(
    "proposedCategories?.categorizationProposals",
    proposedCategories?.categorizationProposals
  );
  return (
    <PageLayout>
      <h2>
        Welcome to Sophie&apos;s Secret Page! If you aren&apos;t Sophie, get
        out!
      </h2>
      <TableContainer>
        <h3>Proposed Categories</h3>
        <Card
          sx={{
            border: "1px solid black",
            borderRadius: "8px",
          }}
        >
          <Table id="proposed-category-table">
            <TableHead>
              <TableRow>
                <TableCell>Vendor Name</TableCell>
                <TableCell>Proposed Subcategory</TableCell>
                <TableCell>Transaction Link</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proposedCategories?.categorizationProposals?.map((proposal) => (
                <TableRow id={`proposal-${proposal.id}`} key={proposal.id}>
                  <TableCell sx={{ maxWidth: "100px", overflow: "auto" }}>
                    <Link
                      to={`${OLD_APP_URL}/carbonizer/vendors/${proposal.Vendor?.id}`}
                      target="_blank"
                    >
                      {proposal.Vendor?.vendorName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {proposal.JoroSubcategory?.joroSubcategory} <br /> (
                    {proposal.JoroSubcategory?.TransactionType?.transactionType}
                    )
                  </TableCell>
                  <TableCell>
                    <Link to={`/new/transactions/${proposal.transactionId}/edit`}>
                      Link
                    </Link>
                  </TableCell>
                  <TableCell>
                    {updateCategoryMutation.isLoading &&
                    updateCategoryMutation.variables.id === proposal.id ? (
                        "Loading..."
                      ) : (
                        <>
                          <Button
                            id="approve-categorization"
                            onClick={() =>
                              updateCategoryMutation.mutate({
                                id: proposal.id,
                                status: "Approved",
                              })
                            }
                          >
                          Approve
                          </Button>
                          <Button
                            id="deny-categorization"
                            onClick={() =>
                              updateCategoryMutation.mutate({
                                id: proposal.id,
                                status: "Denied",
                              })
                            }
                          >
                          Deny
                          </Button>
                        </>
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </TableContainer>

      <TableContainer>
        <h3>Proposed Clean Vendors</h3>
        <Card
          sx={{
            border: "1px solid black",
            borderRadius: "8px",
          }}
        >
          <Table id="proposed-mapping-table">
            <TableHead>
              <TableRow>
                <TableCell>Vendor Name</TableCell>
                <TableCell>Proposed Clean Vendor</TableCell>
                <TableCell>Transaction Link</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proposedVendors?.mappingProposals?.map((proposal) => (
                <TableRow id={`proposal-${proposal.id}`} key={proposal.id}>
                  <TableCell>
                    <Link
                      to={`${OLD_APP_URL}/carbonizer/vendors/${proposal.Vendor?.id}`}
                      target="_blank"
                    >
                      {proposal.Vendor?.vendorName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`${OLD_APP_URL}/carbonizer/vendors/${proposal.CleanVendor?.id}`}
                      target="_blank"
                    >
                      {proposal.CleanVendor?.vendorName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link to={`/new/transactions/${proposal.transactionId}/edit`}>
                      Link
                    </Link>
                  </TableCell>
                  <TableCell>
                    {updateMappingMutation.isLoading &&
                    updateMappingMutation.variables.id === proposal.id ? (
                        "Loading..."
                      ) : (
                        <>
                          <Button
                            id="approve-mapping"
                            onClick={() =>
                              updateMappingMutation.mutate({
                                id: proposal.id,
                                status: "Approved",
                              })
                            }
                          >
                          Approve
                          </Button>
                          <Button
                            id="deny-mapping"
                            onClick={() =>
                              updateMappingMutation.mutate({
                                id: proposal.id,
                                status: "Denied",
                              })
                            }
                          >
                          Deny
                          </Button>
                        </>
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </TableContainer>
    </PageLayout>
  );
}

export default Proposals;
