import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { Link, useMatch, matchPath } from "react-router-dom";
import { COLORS } from "../constants/colors";
import { router } from "../react-routes/router";

const useIsInRouter = (path) => {
  const normalizedPath = useMemo(() => {
    if (path.startsWith("/") || path.startsWith("http")) {
      return path;
    }
    return `/${path}`;
  }, [path]);
  const allPaths = useMemo(
    () => router.routes[0]?.children.map((route) => `/${route.path}`) ?? [],
    []
  );

  return useMemo(
    () =>
      allPaths.some((routerPath) => {
        const matchedPath = matchPath(
          {
            path: routerPath,
          },
          normalizedPath
        );
        return matchedPath && matchedPath.pattern.path !== "/*";
      }),
    [allPaths, normalizedPath]
  );
};

const Anchor = ({ children, className, to }) => {
  return (
    <Box
      as="button"
      className={className}
      onClick={() => {
        window.location.href = to;
      }}
      sx={{
        position: "relative",
        display: "block",
        width: "100%",
        textAlign: "left",
      }}
    >
      {children}
    </Box>
  );
};

export const TabLink = ({ path, children, needsFullRefresh }) => {
  const matches = useMatch(path);
  const inInRouter = useIsInRouter(path);
  const LinkComponent = inInRouter || !needsFullRefresh ? Link : Anchor;

  return (
    <LinkComponent
      to={path}
      className={`tab${matches ? " tab-active" : ""}`}
      aria-current={matches ? "page" : undefined}
    >
      <Box
        pl={2}
        mx={-2}
        py={1}
        sx={{
          cursor: "pointer",
          transition: "all 0.2s ease-in-out",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          backgroundColor: matches ? "rgba(255,255,255,0.04)" : "transparent",
          "*": {
            color: `${COLORS.BLACK} !important`,
            textDecoration: "none !important",
          },
          ".tab-wrapper": {
            transition: "all 0.2s ease-in-out",
            borderRight: matches ? `2px solid ${COLORS.BLACK}` : "none",
          },
          ":hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          ":hover .tab-wrapper": {
            borderRight: `1px solid ${COLORS.BLACK}`,
          },
        }}
      >
        <Box className="tab-wrapper">{children}</Box>
      </Box>
    </LinkComponent>
  );
};
