import React, { Children } from "react";
import { PageLayout as BasicPageLayout } from "../Page";
import Box from "@mui/material/Box";
import { COLORS } from "../../constants/colors";

const CENTER_COLUMN_STYLES = {
  width: "100%",
};

/** @type {React.FC<React.PropsWithChildren>} */
export const PageHeader = () => null;
/** @type {React.FC<React.PropsWithChildren>} */
export const PageMain = () => null;
/** @type {React.FC<React.PropsWithChildren>} */
export const PageRightSidebar = () => null;

/**
 * @param {React.PropsWithChildren} props
 */
export const PageLayout = ({ children }) => {
  const headerContent =
    Children.toArray(children).find((child) => child.type === PageHeader)?.props
      ?.children ?? null;
  const mainContent =
    Children.toArray(children).find((child) => child.type === PageMain)?.props
      ?.children ?? null;
  const sidebarContent =
    Children.toArray(children).find((child) => child.type === PageRightSidebar)
      ?.props?.children ?? null;

  return (
    <Box display="flex" flexDirection="row" position="relative" flex={1}>
      <Box flex={1}>
        <Box
          sx={{
            position: "sticky",
            backgroundColor: COLORS.BEIGE,
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            ...CENTER_COLUMN_STYLES,
            top: "0",
            zIndex: "10",
          }}
          pt={2}
          pb={2}
          ml={2}
        >
          {headerContent}
        </Box>
        <BasicPageLayout sx={{ ...CENTER_COLUMN_STYLES }}>
          <Box sx={{ ...CENTER_COLUMN_STYLES }}>{mainContent}</Box>
        </BasicPageLayout>
      </Box>
      <Box
        p={2}
        mt={13}
        ml={2}
        sx={{
          position: "sticky",
          top: 0,
          height: "min-content",
        }}
      >
        {sidebarContent}
      </Box>
    </Box>
  );
};
