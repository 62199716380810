import React from "react";
import { GenieSearch } from "./GenieSearch";
import { Box } from "@mui/material";
import { GenieDataForm } from "./GenieDataForm";

export const Genie = () => {
  return (
    <Box display="flex" flexDirection="row">
      <Box flex={1}>
        <GenieSearch />
      </Box>
      <Box maxWidth="40vw" flex={1}>
        <GenieDataForm />
      </Box>
    </Box>
  );
};
