import React from "react";
import { Typography, IconButton, List, ListItem, ListItemText, Stack, TextField, } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const PickList = ({ title, activeList, availableList, onActiveItemClick, onAvailableItemClick }) => {
  const [filter, setFilter] = React.useState("");
  const filteredAvailableList = availableList.filter((item) => item?.name?.toLowerCase().includes(filter.toLowerCase()));
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography variant="h4">{title}</Typography>
        <TextField
          variant="standard"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Filter"
        />
        <IconButton onClick={() => setFilter("")}>
          <ClearIcon/>
        </IconButton>

      </Stack>
      <div style={{ height: "600px", maxHeight: "600px", overflowY: "auto", border: "1px solid black" }}>
        <List>
          {activeList.map((item, index) => (
            <ListItem key={index}
              style={{ backgroundColor: "lightblue" }}
              onClick={() => onActiveItemClick(item)}>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
        {activeList?.length > 0 && <hr style={{ margin: "20px 0", border: "1px solid black" }} />}
        <List>
          {filteredAvailableList.map((item, index) => (
            <ListItem key={index} onClick={() => onAvailableItemClick(item.id)}>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
};

export default PickList;
