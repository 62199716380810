import React from "react";
import { Link } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { COLORS } from "../../constants/colors";

const generatePlaceholderURL = (title) =>
  `https://placehold.co/400/${COLORS.LIGHT_GRAY.replace(
    "#",
    ""
  )}/${COLORS.WARM_GRAY.replace("#", "")}?text=${title?.substr(0, 1)}`;

export const SearchResultItem = ({
  imageURL,
  title,
  href,
  subtitle,
  square = false,
  direction = "row",
  objectFit = "contain",
}) => (
  <Box as="li">
    <Box display="flex" flexDirection={direction} py={2} px={2}>
      <Box
        as={"img"}
        src={imageURL ?? generatePlaceholderURL(title)}
        sx={{
          width: "50px",
          height: "50px",
          objectFit,
          borderRadius: square ? "8px" : "50%",
          border: `1px solid ${COLORS.WARM_GRAY}`,
        }}
        mr={2}
      />
      <Box display="flex" flexDirection="column">
        <Link to={href}>{title}</Link>
        <small>{subtitle ?? "N/A"}</small>
      </Box>
    </Box>
    <Divider />
  </Box>
);
