import React, { useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  FormControl,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCarbonizerConstants } from "hooks/useCarbonizerConstants";

export const UserForm = ({ values, onChange, setAllState }) => {
  const user = values?.user ?? {};

  const {
    carbonizerConstants,
    isLoadingCarbonizerConstants,
    carbonizerConstantsError,
  } = useCarbonizerConstants(user.country ?? "US", setAllState);

  useEffect(() => {
    if (carbonizerConstantsError) {
      alert("Unable to load carbon survey defaults. Please try again later.");
    }
  }, [carbonizerConstantsError]);

  if (isLoadingCarbonizerConstants) {
    return <CircularProgress />;
  }
  return (
    <>
      <Accordion sx={{ marginBottom: "8px", borderRadius: "8px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Location Details
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ padding: "8px" }}>
            <TextField
              name="user.zipCode"
              value={user.zipCode}
              onChange={onChange}
              label="Zipcode"
            />
            <FormControl sx={{ marginLeft: "8px" }}>
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                name="user.country"
                labelId="country-label"
                value={user.country ?? "US"}
                onChange={onChange}
                label="Country"
              >
                <MenuItem value="US">United States</MenuItem>
                <MenuItem value="CA">Canada</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ marginBottom: "8px", borderRadius: "8px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Household Details
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ padding: "8px" }}>
            <TextField
              name="user.peopleInHome"
              value={
                user.peopleInHome ??
                carbonizerConstants.peopleInHome?.value ??
                0
              }
              onChange={onChange}
              label="People in Home"
              type="number"
              sx={{ margin: "8px" }}
              inputProps={{ min: 0 }}
            />
            <TextField
              name="user.roomsInHome"
              value={
                user.roomsInHome ?? carbonizerConstants.roomsInHome?.value ?? 0
              }
              onChange={onChange}
              label="Rooms in Home"
              type="number"
              sx={{ margin: "8px" }}
              inputProps={{ min: 0 }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ marginBottom: "8px", borderRadius: "8px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Food Details
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ padding: "8px" }}>
            <TextField
              name="user.weeklyRedMeatMeals"
              value={
                user.weeklyRedMeatMeals ??
                carbonizerConstants.weeklyRedMeatMeals?.value ??
                0
              }
              onChange={onChange}
              label="Weekly Red Meat Meals"
              type="number"
              sx={{ margin: "8px" }}
              inputProps={{ min: 0 }}
            />
            <TextField
              name="user.weeklyWhiteMeatMeals"
              value={
                user.weeklyWhiteMeatMeals ??
                carbonizerConstants.weeklyWhiteMeatMeals?.value ??
                0
              }
              onChange={onChange}
              label="Weekly White Meat Meals"
              type="number"
              sx={{ margin: "8px" }}
              inputProps={{ min: 0 }}
            />
            <TextField
              name="user.weeklyDairyEggMeals"
              value={
                user.weeklyDairyEggMeals ??
                carbonizerConstants.weeklyDairyEggMeals?.value ??
                0
              }
              onChange={onChange}
              label="Weekly Dairy Egg Meals"
              type="number"
              sx={{ margin: "8px" }}
              inputProps={{ min: 0 }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ marginBottom: "8px", borderRadius: "8px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Transportation Details
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ padding: "8px" }}>
            <TextField
              name="user.longHaulFlights"
              value={
                user.longHaulFlights ??
                carbonizerConstants.longHaulFlights?.value ??
                0
              }
              onChange={onChange}
              label="Long Haul Flights"
              type="number"
              sx={{ margin: "8px" }}
              inputProps={{ min: 0 }}
            />
            <TextField
              name="user.medHaulFlights"
              value={
                user.medHaulFlights ??
                carbonizerConstants.medHaulFlights?.value ??
                0
              }
              onChange={onChange}
              label="Med Haul Flights"
              type="number"
              sx={{ margin: "8px" }}
              inputProps={{ min: 0 }}
            />
            <TextField
              name="user.shortHaulFlights"
              value={
                user.shortHaulFlights ??
                carbonizerConstants.shortHaulFlights?.value ??
                0
              }
              onChange={onChange}
              label="Short Haul Flights"
              type="number"
              sx={{ margin: "8px" }}
              inputProps={{ min: 0 }}
            />
          </Box>

          <Box sx={{ padding: "8px" }}>
            <Select
              name="user.commuteMode"
              value={
                user.commuteMode ??
                carbonizerConstants.commuteMode?.value ??
                "COMPACT"
              }
              onChange={onChange}
              label="Commute Mode"
              sx={{ margin: "8px" }}
            >
              <MenuItem value="SEDAN">Sedan</MenuItem>
              <MenuItem value="COMPACT">Compact</MenuItem>
              <MenuItem value="HYBRID">Hybrid</MenuItem>
              <MenuItem value="ELECTRIC">Electric</MenuItem>
              <MenuItem value="SUV">SUV</MenuItem>
              <MenuItem value="HYBRID_SUV">Hybrid SUV</MenuItem>
              <MenuItem value="RIDESHARE">Rideshare</MenuItem>
              <MenuItem value="PUBLIC_TRANSIT">Public Transit</MenuItem>
              <MenuItem value="BIKE">Bike</MenuItem>
              <MenuItem value="WALK">Walk</MenuItem>
            </Select>
            <TextField
              name="user.commuteDistance"
              value={
                user.commuteDistance ??
                carbonizerConstants.commuteDistance?.value ??
                0
              }
              onChange={onChange}
              label="Commute Distance"
              type="number"
              helperText="Miles round trip"
              sx={{ margin: "8px" }}
              inputProps={{ min: 0 }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ marginBottom: "8px", borderRadius: "8px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Energy Details
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ padding: "8px" }}>
            <TextField
              name="user.solarEnergyPercent"
              value={
                user.solarEnergyPercent ??
                carbonizerConstants.solarEnergyPercent?.value ??
                0
              }
              onChange={onChange}
              label="Solar Energy Percent"
              type="number"
              sx={{ margin: "8px", width: 200 }}
              inputProps={{ min: 0, max: 1, step: 0.05 }}
              helperText={carbonizerConstants.cleanEnergyPercent?.value > 1 ? "More than 100% Solar Energy? Check yourself." : ""}
            />
            <TextField
              name="user.cleanEnergyPercent"
              value={
                user.cleanEnergyPercent ??
                carbonizerConstants.cleanEnergyPercent?.value ??
                0
              }
              onChange={onChange}
              label="Clean Energy Percent"
              type="number"
              sx={{ margin: "8px", width: 200 }}
              inputProps={{ min: 0, max: 1, step: 0.05 }}
              helperText={carbonizerConstants.cleanEnergyPercent?.value > 1 ? "More than 100% Clean Energy? Check yourself." : ""}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ marginBottom: "8px", borderRadius: "8px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Goods and Services Details
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ padding: "8px" }}>
            <TextField
              name="user.weeklyGoodsAndServices"
              value={
                user.weeklyGoodsAndServices ??
                carbonizerConstants.weeklyGoodsAndServicesDollars?.value ??
                0
              }
              onChange={onChange}
              label="Weekly Goods and Services"
              type="number"
              sx={{ padding: "8px", margin: "8px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              inputProps={{ min: 0 }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
