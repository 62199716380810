/* eslint-disable no-console */
import React, { useCallback, useState } from "react";
import _ from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Fade,
  Modal,
  Tooltip,
} from "@mui/material";
import { BarChart, PieChart } from "@mui/x-charts";
import { FormContainer } from "./FormContainer";
import { UserForm } from "./UserForm";
import { TransactionsForm } from "./TransactionsForm";
import { COLORS } from "constants/colors";
import { useSubcategories } from "hooks/useSubcategories";
import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "components/utils";
import { v4 } from "uuid";
import { JsonContainer } from "./JsonContainer";
import Info from "@mui/icons-material/Info";
import ExpandMore from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
const DATE_FORMAT = "YYYY-MM-DD";


const defaultState = {
  user: {},
  transactions: [],
  startDate: dayjs().format(DATE_FORMAT),
  endDate: dayjs().format(DATE_FORMAT),
  userCount: 1
};

const hasMultipleCategories = (result) => {
  if (!result?.footprint) {
    return false;
  }
  const categoriesWithData = Object.keys(result.footprint).filter(
    (category) => result?.footprint[category] > 0
  );
  return categoriesWithData.length > 1;
};

const getPieChartData = (result) => {
  return [
    {
      id: 0,
      value: result?.footprint?.foodKgCO2e,
      label: "Food",
      color: "#F5793A",
    },
    {
      id: 1,
      value: result?.footprint?.goodsAndServicesKgCO2e,
      label: "Shopping",
      color: "#E59FFE",
    },
    {
      id: 2,
      value: result?.footprint?.homeKgCO2e,
      label: "Utilities",
      color: "#F2B80D",
    },
    {
      id: 3,
      value: result?.footprint?.transportKgCO2e,
      label: "Travel",
      color: "#8B98A2",
    },
  ];
};

const FormInnards = ({
  showJson,
  state,
  subcategoriesData,
  setKeyedState,
  setAllState
}) => {
  return showJson ? (
    <React.Fragment>
      <JsonContainer state={state} setState={setAllState} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          User
          <Tooltip
            arrow
            title="If no user fields are provided carbon survey default will be used."
            placement="bottom-end"
          >
            <Info color="secondary" />
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails>
          <UserForm onChange={setKeyedState} values={state} setAllState={setAllState} />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          Transactions{" "}
          <Tooltip
            arrow
            title="If transactions are not added, the result will use only the provided user fields."
            placement="bottom-end"
          >
            <Info color="secondary" />
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails>
          <TransactionsForm
            onChange={setKeyedState}
            values={state}
            subcategories={subcategoriesData}
          />
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export const Carbonizer = () => {
  const [isCalculating, setIsCalculating] = useState(false);
  const [form1State, setForm1State] = useState(defaultState);
  const [result1, setResult1] = useState(null);
  const [form2State, setForm2State] = useState(null);
  const [result2, setResult2] = useState(null);
  const [formAPIState, setFormAPIState] = useState([]);
  const [form1StateIsJson, setForm1StateIsJson] = useState(false);
  const [form2StateIsJson, setForm2StateIsJson] = useState(false);
  const { data: subcategoriesData } = useSubcategories();

  const onChange = useCallback((setState) => (e) => {
    setState((formState) => ({
      ..._.set(_.cloneDeep(formState), e.target.name, e.target.value),
    }));
  }, []);

  const onBulkUpdate = useCallback((setState) => (e) => {
    setState(e);
  }, []);

  const createComparison = () => {
    const newState = _.cloneDeep(form1State);
    newState.transactions = newState.transactions.map((transaction) => ({
      ...transaction,
      id: v4(),
    }));
    setForm2State(newState);
  };

  const { mutateAsync } = useMutation({
    mutationFn: (formState) =>
      makeApiRequest({
        endpoint: "/api/carbonize",
        method: "POST",
        json: true,
        body: {
          transactionPreview: formState,
        },
      }),
  });

  const resetResults = () => {
    setResult1(null);
    setResult2(null);
  };

  const sendToCarbonizer = async () => {
    const formsToSubmit = [form1State, form2State]
      .filter((x) => x)
      .map((formState) => {
        if (
          formState?.transactions?.length &&
          subcategoriesData?.subcategories?.length
        ) {
          return {
            ...formState,
            transactions: formState.transactions.map((transaction) => {
              const matching = subcategoriesData.subcategories.find(
                (sub) => sub.id == transaction.joroSubcategoryId
              );
              const userAddedMetadata = {};
              if (transaction.vendorHasSustainableOption) {
                userAddedMetadata["isSustainableOption"] = transaction.isGreenOffering === "true";
              }

              return {
                ...transaction,
                userAddedMetadata: userAddedMetadata,
                joroSubcategoryKey: matching?.joroSubcategoryKey,
                kgCO2ePerDollar: matching?.kgCO2ePerDollar,
                inflationYear: matching?.inflationYear,
                summaryCategory: matching?.JoroCategory?.summaryCategory,
                hasSustainableJoroSubcategory: matching?.isSustainable,
              };
            }),
          };
        }
        return formState;
      });
    formsToSubmit.map((form) => {
      setFormAPIState([...formAPIState, form]);
    });
    setIsCalculating(true);
    await Promise.all(
      formsToSubmit.map(async (formState, index) => {
        try {
          const response = await mutateAsync(formState);
          if (index === 0) {
            setResult1(response.result);
          }
          if (index === 1) {
            setResult2(response.result);
          }
        } catch (err) {
          alert(err.message);
          console.error(err);
        }
      })
    );
    setIsCalculating(false);
  };

  const shouldShowResultsTwo =
    !result2 &&
    (hasMultipleCategories(result1) || hasMultipleCategories(result2));

  return (
    <Box display="flex" flexDirection="row" position="relative" flex={1}>
      <Box flex={1}>
        <Box
          sx={{
            position: "sticky",
            backgroundColor: COLORS.BEIGE,
            width: "100%",
            top: "0",
            zIndex: "10",
          }}
          pt={6}
          pb={2}
          ml={2}
        >
          <Box minWidth="200px">
            <Box
              ml={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                pr={1}
                xs={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  backgroundColor: COLORS.WHITE,
                }}
              >
                <Tooltip
                  arrow
                  title="This tool is an interface for the Carbonizer. Potential use cases include carbon calculation QA, scenario generation for counterfactuals and carbon equivalencies, and more. Click the Calculate button to see the results."
                  placement="bottom-end"
                >
                  <Info color="secondary" />
                </Tooltip>
              </Box>
              <Box as="h4" mr={2}>
                Carbonizer Tool
              </Box>
              <Button
                variant="contained"
                onClick={sendToCarbonizer}
                size="small"
              >
                {isCalculating ? "Calculating..." : "Calculate"}
              </Button>
            </Box>
          </Box>
        </Box>

        <Box mx={2} zIndex="0">
          <Box
            display="flex"
            flexDirection={"row"}
            sx={{
              height: "83vh",
            }}
          >
            {form1State && (
              <FormContainer width="40vw">
                <h4>Initial Carbonizer Scenario</h4>
                <Box flexDirection="row" display="flex" alignItems="center">
                  <ButtonGroup
                    size="small"
                    sx={{
                      display: "flex",
                      flex: 1,
                      button: { flexGrow: 1 },
                    }}
                  >
                    <Button
                      onClick={() => setForm1StateIsJson(false)}
                      variant={form1StateIsJson ? "outlined" : "contained"}
                      flex={1}
                    >
                      Form
                    </Button>
                    <Button
                      onClick={() => setForm1StateIsJson(true)}
                      variant={form1StateIsJson ? "contained" : "outlined"}
                    >
                      JSON
                    </Button>
                  </ButtonGroup>
                </Box>
                <FormInnards
                  showJson={form1StateIsJson}
                  state={form1State}
                  parseState={() => {}}
                  setKeyedState={onChange(setForm1State)}
                  setAllState={onBulkUpdate(setForm1State)}
                  subcategoriesData={subcategoriesData?.subcategories ?? []}
                />
              </FormContainer>
            )}
            {form2State ? (
              <FormContainer width="40vw">
                <h4>Comparable Carbonizer Scenario</h4>
                <Box flexDirection="row" display="flex" alignItems="center">
                  <ButtonGroup
                    size="small"
                    sx={{
                      display: "flex",
                      flex: 1,
                      button: { flexGrow: 1 },
                    }}
                  >
                    <Button
                      onClick={() => setForm2StateIsJson(false)}
                      variant={form2StateIsJson ? "outlined" : "contained"}
                      flex={1}
                    >
                      Form
                    </Button>
                    <Button
                      onClick={() => setForm2StateIsJson(true)}
                      variant={form2StateIsJson ? "contained" : "outlined"}
                    >
                      JSON
                    </Button>
                  </ButtonGroup>
                </Box>
                <FormInnards
                  showJson={form2StateIsJson}
                  state={form2State}
                  parseState={() => {}}
                  setKeyedState={onChange(setForm2State)}
                  setAllState={onBulkUpdate(setForm2State)}
                  subcategoriesData={subcategoriesData?.subcategories ?? []}
                />
              </FormContainer>
            ) : (
              <Box p={4}>
                <Tooltip
                  arrow
                  title="This button creates an additional scenario to compare."
                  placement="bottom-end"
                  enterDelay={300}
                >
                  <Button
                    sx={{ flex: 1, height: "min-content" }}
                    onClick={createComparison}
                    variant="contained"
                  >
                    Compare
                  </Button>
                </Tooltip>
              </Box>
            )}
            {result1 && (
              <Modal open={!!result1} onClose={resetResults}>
                <Fade in={!!result1}>
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ width: "100vw", height: "100vh" }}
                    >
                      <Box
                        sx={{
                          padding: "16px",
                          backgroundColor: COLORS.BEIGE,
                          borderRadius: "8px",
                          width: "65vw",
                          height: "80vh",
                          overflow: "scroll",
                        }}
                      >
                        <Button variant="text" onClick={resetResults}>
                          x
                        </Button>
                        <Box display="flex" flexDirection="row">
                          <Box
                            sx={{
                              padding: "24px",
                              justifyContent: "flex-start",
                              width: "100%",
                              flex: 1,
                            }}
                          >
                            <h3>Initial</h3>
                            <Box>
                              <h4>Total Carbon</h4>
                              <p>{result1?.totalKgCO2e?.toFixed(2)} kgCO2e</p>
                            </Box>
                            <Box>
                              <h4>Total Monies</h4>
                              <p>${result1?.totalAmount?.toFixed(2)}</p>
                            </Box>
                            {shouldShowResultsTwo && (
                              <Box>
                                <PieChart
                                  slotProps={{ legend: { hidden: true } }}
                                  series={[
                                    {
                                      cornerRadius: 4,
                                      innerRadius: 24,
                                      paddingAngle: 2,
                                      data: getPieChartData(result1),
                                    },
                                  ]}
                                  width={300}
                                  height={200}
                                />
                              </Box>
                            )}
                          </Box>
                          {result2 && (
                            <Box
                              sx={{
                                padding: "16px",
                                justifyContent: "flex-start",
                                flex: 1,
                              }}
                            >
                              <h3>Comparsion</h3>
                              <Box>
                                <h4>Total Carbon </h4>
                                <p>{result2?.totalKgCO2e?.toFixed(2)} kgCO2e</p>
                              </Box>
                              <Box>
                                <h4>Total Monies </h4>
                                <p>${result2?.totalAmount?.toFixed(2)}</p>
                              </Box>
                            </Box>
                          )}
                        </Box>
                        {result2 && (
                          <Box display="flex" justifyContent="center">
                            <BarChart
                              xAxis={[
                                {
                                  scaleType: "band",
                                  data: ["Initial", "Comparison"],
                                },
                              ]}
                              yAxis={[
                                {
                                  label: "Emissions (kgCO₂e)",
                                },
                              ]}
                              series={[
                                {
                                  stack: "A",
                                  data: [
                                    result1?.footprint?.foodKgCO2e,
                                    result2?.footprint?.foodKgCO2e,
                                  ],
                                  label: "Food",
                                  color: "#F5793A",
                                  valueFormatter: (value) =>
                                    `${Math.round(value * 100) / 100} kgCO₂e`,
                                },
                                {
                                  stack: "A",
                                  data: [
                                    result1?.footprint?.goodsAndServicesKgCO2e,
                                    result2?.footprint?.goodsAndServicesKgCO2e,
                                  ],
                                  label: "Shopping",
                                  color: "#E59FFE",
                                  valueFormatter: (value) =>
                                    `${Math.round(value * 100) / 100} kgCO₂e`,
                                },
                                {
                                  stack: "A",
                                  data: [
                                    result1?.footprint?.homeKgCO2e,
                                    result2?.footprint?.homeKgCO2e,
                                  ],
                                  label: "Utilities",
                                  color: "#F2B80D",
                                  valueFormatter: (value) =>
                                    `${Math.round(value * 100) / 100} kgCO₂e`,
                                },
                                {
                                  stack: "A",
                                  data: [
                                    result1?.footprint?.transportKgCO2e,
                                    result2?.footprint?.transportKgCO2e,
                                  ],
                                  label: "Travel",
                                  color: "#8B98A2",
                                  valueFormatter: (value) =>
                                    `${Math.round(value * 100) / 100} kgCO₂e`,
                                },
                              ]}
                              width={800}
                              height={350}
                            />
                          </Box>
                        )}
                        {(!!result1?.transactions.length ||
                          !!result2?.transactions.length) && (
                          <Box display="flex" flexDirection="column">
                            <h3>Transactions</h3>
                            {[
                              ...(result1?.transactions ?? []),
                              ...(result2?.transactions ?? []),
                            ].map((transaction, index) => {
                              let scenario = "Initial";
                              let originalTransaction =
                                form1State.transactions.find(
                                  (t) => t.id === transaction.id
                                );

                              if (!originalTransaction) {
                                scenario = "Comparison";
                                originalTransaction =
                                  form2State.transactions.find(
                                    (t) => t.id === transaction.id
                                  );
                              }

                              const subcategory =
                                subcategoriesData?.subcategories?.find(
                                  (sub) =>
                                    sub.id ===
                                    originalTransaction?.joroSubcategoryId
                                );
                              return (
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginBottom: "8px",
                                    backgroundColor: COLORS.LIGHT_GRAY,
                                    paddingY: 2,
                                    paddingX: 1,
                                    borderRadius: 2,
                                  }}
                                >
                                  <Box>
                                    <Box
                                      as="small"
                                      sx={{ margin: 0, lineHeight: 0 }}
                                    >
                                      {scenario} scenario transaction
                                    </Box>
                                    <Box
                                      as="small"
                                      sx={{
                                        margin: 0,
                                        lineHeight: 0,
                                        display: "block ",
                                        paddingBottom: 1,
                                        paddingTop: 0.5,
                                      }}
                                    >
                                      <strong>
                                        ${originalTransaction.amount ?? 0}
                                      </strong>{" "}
                                      at{" "}
                                      <strong>
                                        {subcategory?.joroSubcategory ?? "n/a"}
                                      </strong>
                                    </Box>
                                    <Box as="h4" sx={{ margin: 0 }}>
                                      {transaction.kgCO2e.toFixed(2)} kgCO2e
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Fade>
              </Modal>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
