/**
 * separateObjectByPrefix is a function that will take a single
 * object and separate it into multiple objects bases on a list of keys passed in.
 * @type {<Keys extend Array<string>(obj: Record<string, unknown>, keys: Keys) => Record<Keys, unknown>}
 */
export const separateObjectByPrefix = (obj, keys) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    let used = false;
    keys.forEach((prefix) => {
      if (key.startsWith(prefix) && !used) {
        used = true;
        if (typeof result[prefix] !== "object") {
          result[prefix] = {};
        }
        const [property, id] = key.split(":");
        if (id) {
          if (typeof result[prefix][id] !== "object") {
            result[prefix][id] = {};
          }
          result[prefix][id][property] = obj[key];
        } else {
          result[prefix][property] = obj[key];
        }
      }
    });
    if (!used) {
      if (typeof result.rest !== "object") {
        result.rest = {};
      }
      result.rest[key] = obj[key];
    }
  });
  return result;
};
