const Urls = {
  CloudFront: "https://d1lcxgis7w2e3k.cloudfront.net",
  S3: "https://joro-assets.s3.amazonaws.com",
  Imgix: "https://cmmns.imgix.net",
};

export const getContainerWidth =
  typeof window !== "undefined" ? window.innerWidth : 1280;
export const IMAGE_MULTIPLIER = 2;

/**
 * resolveImgixUrl is a function that can take a url, and resolve it to an imgix url.
 * this will allow use to continue to use paths from the old cdn, and aws, but then
 * point them to imgix so we can leverage the rendering api to optimize the images.
 */
export const resolveImgixUrl = (path) => {
  if (path.startsWith(Urls.CloudFront)) {
    return path.replace(Urls.CloudFront, Urls.Imgix);
  }
  if (path.startsWith(Urls.S3)) {
    return path.replace(Urls.S3, Urls.Imgix);
  }
  if (path.startsWith("/")) {
    return `${Urls.Imgix}${path}`;
  }
  return path;
};
/**
 * createQueryParams is a function that will take an object of options, and create
 * a query string that can be appended to an imgix url to leverage the rendering api.
 *
 * [See the full docs](https://docs.imgix.com/apis/rendering)
 */
export const createQueryParams = (options = {}) => {
  const { width, height, invert, fit, sat, auto } = options;
  const params = [];
  if (width) {
    params.push(`w=${width}`);
  }
  if (height) {
    params.push(`h=${height}`);
  }
  if (invert) {
    params.push("invert=true");
  }
  if (fit) {
    params.push(`fit=${fit}`);
  }
  if (typeof sat === "number" && sat >= -100 && sat <= 100) {
    params.push(`sat=${sat}`);
  }
  if (typeof auto === "string") {
    params.push(`auto=${auto}`);
  }
  return `?${params.join("&")}`;
};

/**
 * resolveUrl is a function that will take a url, and an object of options, and
 * return a url that can be used to render the image with imgix.
 * @param url
 * @param options
 * @returns
 * ```ts
 * const url = resolveUrl(
 *   "https://d1lcxgis7w2e3k.cloudfront.net/...",
 *   { width: 100, height: 100 }
 * );
 */
export const resolveUrl = (url, options = {}) => {
  const maybeImgixUrl = resolveImgixUrl(url);
  if (maybeImgixUrl.startsWith(Urls.Imgix)) {
    const params = createQueryParams(options);
    return `${maybeImgixUrl.split("?")[0]}${params}`;
  }
  return maybeImgixUrl;
};

/**
 * getImageDimensions is a function that will take an aspect ratio, and optional
 * height and width, and return the height and width that the image should be
 * rendered at.
 *
 * This function will make some assumptions to be aware of:
 * - if you pass a aspectRatio of 1, it will assume you want a square image, and
 *  will return a width of 1/2 the screen width
 */
export const getImageDimensions = ({
  sizeMultiplier = 2,
  width,
  aspectRatio,
  height,
}) => {
  if (height && width) {
    return {
      height: height * sizeMultiplier,
      width: width * sizeMultiplier,
    };
  }
  if (height && aspectRatio) {
    return {
      height: height * sizeMultiplier,
      width: height * sizeMultiplier * aspectRatio,
    };
  }
  if (width && aspectRatio) {
    return {
      height: (width * sizeMultiplier) / aspectRatio,
      width: width * sizeMultiplier,
    };
  }

  throw new Error("Must provide either height width or both");
};

export const aspectRatioToNumber = (aspectRatio) => {
  switch (aspectRatio) {
    case "16:9":
      return 16 / 9;
    case "4:3":
      return 4 / 3;
    case "1:1":
      return 1;
    case "3:4":
      return 3 / 4;
    case "9:16":
      return 9 / 16;
    default:
      return undefined;
  }
};
