import React from "react";
import Box from "@mui/material/Box";
import { COLORS } from "../../constants/colors";
import { InputLabel, TextField } from "@mui/material";

import moment from "moment";
import { Callout } from "components/Callout";

export const OfferModal = ({
  onChange,
  onImageUpload,
  offerImage,
  id,
  offerTitle,
  offerDescription,
  offerUrl,
  offerEndDate,
  offerStartDate,
}) => {
  return (
    <Box padding={6} flexDirection="column" display="flex">
      <Callout type="info">
        {typeof id === "string" && id.startsWith("new")
          ? "Create a new offer"
          : "Editing an offer, please save with pages save button."}
      </Callout>
      <Box paddingBottom={2} />
      <Box
        mb={4}
        sx={{
          width: "400px",
          borderRadius: "8px",
          border: `1px solid ${COLORS.WARM_GRAY}`,
          overflow: "hidden",
          position: "relative",
          "::after": {
            display: "block",
            content: '""',
            paddingBottom: "56.25%",
          },
        }}
      >
        <Box
          as="input"
          type="file"
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: "0",
            cursor: "pointer",
            zIndex: "2",
          }}
          onChange={onImageUpload}
          accept="image/jpeg"
          name={`offerImage:${id}`}
        />
        <Box
          as="img"
          src={offerImage}
          name={`offerImage:${id}`}
          alt={offerTitle}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "contain",
            zIndex: "1",
          }}
          onChange={onChange}
        />
      </Box>
      <TextField
        label="Title"
        name={`offerTitle:${id}`}
        value={offerTitle}
        onChange={onChange}
      />
      <Box paddingBottom={2} />
      <TextField
        label="Description"
        name={`offerDescription:${id}`}
        value={offerDescription}
        onChange={onChange}
      />
      <Box paddingBottom={2} />
      <TextField
        label="URL"
        name={`offerUrl:${id}`}
        value={offerUrl}
        onChange={onChange}
      />
      <Box paddingBottom={2} />

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          <InputLabel>Start Date</InputLabel>
          <input
            name={`offerStartDate:${id}`}
            value={
              offerStartDate
                ? moment(offerStartDate).format("YYYY-MM-DD")
                : undefined
            }
            type="date"
            onChange={onChange}
          />
        </Box>
        <Box>
          <InputLabel>End Date</InputLabel>
          <input
            name={`offerEndDate:${id}`}
            value={
              offerEndDate
                ? moment(offerEndDate).format("YYYY-MM-DD")
                : undefined
            }
            type="date"
            onChange={onChange}
          />
        </Box>
      </Box>
      <Box paddingBottom={4} />
      <Callout type="warn">
        Start date is required! Start date dictates when the offer will be
        featured in the app, if you do not want the offer to be featured, set
        the start date earlier then{" "}
        {moment().subtract(5, "days").format("YYYY/MM/DD")}.
      </Callout>
    </Box>
  );
};
