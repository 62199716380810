import { useMutation, useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../components/utils";

export const useContent = ({ search }) => {
  return useQuery(["contentSearch", { search }], () => {
    return makeApiRequest({
      endpoint: `/api/content?search=${search}`,
      method: "GET",
      json: true,
    });
  });
};

export const useContentById = (id) => {
  return useQuery(
    ["contentById", { id }],
    () => {
      return makeApiRequest({
        endpoint: `/api/content/${id}`,
        method: "GET",
        json: true,
      });
    },
    {
      enabled: !!id,
    }
  );
};

export const useContentUpdate = (id) => {
  return useMutation((body) => {
    return makeApiRequest({
      endpoint: `/api/content${id ? `/${id}` : ""}`,
      method: "PUT",
      json: true,
      body,
    });
  });
};
