import React from "react";
import { PageLayout } from "./Page";

export const Welcome = () => {
  return (
    <PageLayout>
      <h1>Welcome to 🥔</h1>
      <p>
        This is the improved spud, to go to the ol&apos; potato{" "}
        <a href="/home">click here</a>
      </p>
    </PageLayout>
  );
};
