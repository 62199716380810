import React from "react";
import { Link, useLoaderData, useSearchParams } from "react-router-dom";
import { makeApiRequest } from "./utils";
import { useQuery, useMutation } from "@tanstack/react-query";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { OLD_APP_URL } from "../constants/potato";
import { useSubcategories } from "../hooks/useSubcategories";

const getTransaction = async (transactionId) => {
  const data = await makeApiRequest({
    endpoint: `/api/transactions/${transactionId}`,
    method: "GET",
  });
  return data;
};

export async function loader({ params }) {
  const transaction = await getTransaction(params.id);
  return transaction;
}

const getCleanVendors = async () => {
  const response = await makeApiRequest({
    endpoint: "/api/vendors?cleanedOnly=true",
    method: "GET",
  });
  return response;
};

const createVendorCategorizationProposal = async (opts) => {
  const response = await makeApiRequest({
    endpoint: "/api/vendorCategorizationProposals",
    method: "POST",
    body: JSON.stringify(opts),
    additionalHeaders: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

const createVendorMappingProposal = async (opts) => {
  const response = await makeApiRequest({
    endpoint: "/api/vendorMappingProposals",
    method: "POST",
    body: JSON.stringify(opts),
    additionalHeaders: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

const useCleanVendors = () => {
  const { data } = useQuery({
    queryKey: ["cleanVendors"],
    queryFn: getCleanVendors,
  });
  const cleanVendors = React.useMemo(
    () =>
      data?.vendors?.map((vendor) => ({
        label: `${vendor.vendorName} (${vendor?.JoroSubcategory?.joroSubcategory})`,
        value: vendor.id,
      })) || [],
    [data]
  );
  return cleanVendors;
};

const useAutocompleteSelection = () => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  return { value, setValue, inputValue, setInputValue };
};

const useCreateVendorCategorizationProposal = (redirectUri) => {
  const mutation = useMutation({
    mutationFn: createVendorCategorizationProposal,
    onSuccess: ({ error }) => {
      if (!error && redirectUri) {
        alert(
          "Proposed vendor categorization submitted successfully! Redirecting to transaction page..."
        );
        setTimeout(() => {
          window.location.href = OLD_APP_URL + decodeURI(redirectUri);
        }, 1000);
      }
    },
  });
  return mutation;
};

const useCreateVendorMappingProposal = (redirectUri) => {
  const mutation = useMutation({
    mutationFn: createVendorMappingProposal,
    onSuccess: ({ error }) => {
      if (!error && redirectUri) {
        alert(
          "Proposed vendor mapping submitted successfully! Redirecting to transaction page..."
        );
        setTimeout(() => {
          window.location.href = OLD_APP_URL + decodeURI(redirectUri);
        }, 1000);
      }
    },
  });
  return mutation;
};

const Transaction = () => {
  const [searchParams] = useSearchParams();
  const redirectUri = searchParams.get("redirectUrl") ?? "";
  const { transaction } = useLoaderData();
  const vendorSelection = useAutocompleteSelection();
  const subcategorySelection = useAutocompleteSelection();

  const cleanVendors = useCleanVendors();
  const { subcategoryOptions: subcategories } = useSubcategories();
  const vendorCategorizationMutation =
    useCreateVendorCategorizationProposal(redirectUri);
  const vendorMappingMutation = useCreateVendorMappingProposal(redirectUri);

  const onSubmitCategorization = () => {
    vendorCategorizationMutation.mutate({
      transactionId: transaction.id,
      vendorId: transaction.vendorId,
      proposedSubcategoryId: subcategorySelection.value.value,
    });
  };

  const onClickMapping = () => {
    vendorMappingMutation.mutate({
      transactionId: transaction.id,
      vendorId: transaction.vendorId,
      proposedCleanVendorId: vendorSelection.value.value,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h1" mb={1}>
          {transaction?.memo}
        </Typography>
        <Typography variant="h4" mb={4}>
          Vendor: {transaction?.Vendor?.vendorName || "None indentified"}
        </Typography>
      </Grid>
      <Grid item xs={6} align="right">
        <Typography paragraph fontSize={14} mb={1}>
          <a
            href={`${OLD_APP_URL}/transactions/${transaction?.id}/edit${
              redirectUri ? `?redirectUri=${redirectUri}` : ""
            }`}
          >
            View Transaction in Old Potato &gt;
          </a>
        </Typography>
        {!!redirectUri && (
          <Typography paragraph fontSize={14}>
            <Link
              onClick={() =>
                (window.location.href = OLD_APP_URL + decodeURI(redirectUri))
              }
              id="back-to-redirect"
            >
              Back to Transaction List &gt;
            </Link>
          </Typography>
        )}
      </Grid>
      {/* Transaction Box */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h3" mb={2}>
              Transaction Details
            </Typography>

            <Table sx={{ minWidth: 650 }} size="small">
              <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "80%" }} />
              </colgroup>
              <TableBody>
                <TableRow name="id">
                  <TableCell component="th" scope="row">
                    Transaction ID:
                  </TableCell>
                  <TableCell align="left">{transaction?.id}</TableCell>
                </TableRow>
                <TableRow name="date">
                  <TableCell component="th" scope="row">
                    Date:
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.transactionDate}
                  </TableCell>
                </TableRow>
                <TableRow name="userId">
                  <TableCell component="th" scope="row">
                    User ID:
                  </TableCell>
                  <TableCell align="left">{transaction?.userId}</TableCell>
                </TableRow>
                <TableRow name="amount">
                  <TableCell component="th" scope="row">
                    Amount:
                  </TableCell>
                  <TableCell align="left">${transaction?.amount}</TableCell>
                </TableRow>
                <TableRow name="kgCO2e">
                  <TableCell component="th" scope="row">
                    Emissions:
                  </TableCell>
                  <TableCell align="left">{transaction?.kgCO2e}</TableCell>
                </TableRow>
                <TableRow name="categorization">
                  <TableCell component="th" scope="row">
                    Categorization:
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.JoroSubcategory.joroSubcategory} (
                    {transaction?.JoroSubcategory.JoroCategory.joroCategory})
                  </TableCell>
                </TableRow>
                <TableRow name="modification">
                  <TableCell component="th" scope="row">
                    Has been modified:
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.modified
                      ? transaction?.isJoroEdited
                        ? "By Commons"
                        : "By User"
                      : "No"}
                  </TableCell>
                </TableRow>
                <TableRow name="manuallyAdded">
                  <TableCell component="th" scope="row">
                    Manually added by user:
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.manuallyAdded ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow
                  name="sustainability"
                  sx={{ "th, td": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Sustainability:
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.sustainableChoiceSource
                      ? `From: ${transaction?.sustainableChoiceSource}`
                      : "Not sustainable"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
      {transaction?.Vendor && (
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h3" id="vendor-details-accordion">
                Vendor Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table sx={{ minWidth: 650 }} size="small">
                <colgroup>
                  <col style={{ width: "30%" }} />
                  <col style={{ width: "80%" }} />
                </colgroup>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Vendor ID:
                    </TableCell>
                    <TableCell align="left">
                      {transaction?.Vendor?.id}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Vendor Name:
                    </TableCell>
                    <TableCell align="left">
                      {transaction?.Vendor?.vendorName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Vendor Subcategory:
                    </TableCell>
                    <TableCell align="left">
                      {transaction?.Vendor?.JoroSubcategories
                        ?.joroSubcategory ? (
                          <>
                            {
                              transaction?.Vendor?.JoroSubcategories
                                ?.joroSubcategory
                            }{" "}
                          (
                            {
                              transaction?.Vendor?.JoroSubcategories
                                ?.TransactionType?.transactionType
                            }
                          )
                          </>
                        ) : (
                          "None"
                        )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Is clean name:
                    </TableCell>
                    <TableCell align="left">
                      {transaction?.Vendor?.isCleanVendorName ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Is matchable:
                    </TableCell>
                    <TableCell align="left">
                      {transaction?.Vendor?.isMatchable ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ "th, td": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Corrected Vendor ID:
                    </TableCell>
                    <TableCell align="left">
                      {transaction?.Vendor?.correctedVendorId
                        ? transaction?.Vendor?.correctedVendorId
                        : "None"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box mt={6} sx={{ padding: 2, border: "2px solid orange" }}>
                <Typography variant="h4">Edit Vendor Section</Typography>
                <Grid container>
                  <Grid item xs={6} sx={{ padding: 2 }}>
                    <Typography paragraph>
                      Assign this vendor to a known clean vendor?
                    </Typography>
                    <Autocomplete
                      value={vendorSelection.value}
                      onChange={(event, newValue) => {
                        vendorSelection.setValue(newValue);
                      }}
                      inputValue={vendorSelection.inputValue}
                      onInputChange={(event, newInputValue) => {
                        vendorSelection.setInputValue(newInputValue);
                      }}
                      options={cleanVendors}
                      renderInput={(params) => (
                        <TextField {...params} label="Clean Vendor" />
                      )}
                    />
                    <Grid
                      container
                      justifyContent="flex-end"
                      sx={{ paddingTop: 2 }}
                    >
                      {vendorMappingMutation.data?.error ? (
                        <Typography color="red">
                          Error: {vendorMappingMutation.data?.error}
                        </Typography>
                      ) : (
                        <Button
                          variant="contained"
                          id="submit-mapping"
                          disabled={
                            !vendorSelection.value ||
                            !vendorMappingMutation.isIdle
                          }
                          onClick={onClickMapping}
                        >
                          {vendorMappingMutation.isLoading
                            ? "Loading..."
                            : vendorMappingMutation.isSuccess
                              ? "Submitted!"
                              : "Propose Rule"}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{ padding: 2 }}>
                    <Typography paragraph>
                      Assign this vendor to a known subcategory?
                    </Typography>
                    <Autocomplete
                      value={subcategorySelection.value}
                      onChange={(_, newValue) => {
                        subcategorySelection.setValue(newValue);
                      }}
                      inputValue={subcategorySelection.inputValue}
                      onInputChange={(event, newInputValue) => {
                        subcategorySelection.setInputValue(newInputValue);
                      }}
                      options={subcategories}
                      renderInput={(params) => (
                        <TextField {...params} label="Subcategories" />
                      )}
                    />
                    <Grid
                      container
                      justifyContent="flex-end"
                      sx={{ paddingTop: 2 }}
                    >
                      {vendorCategorizationMutation.data?.error ? (
                        <Typography color="red">
                          Error: {vendorCategorizationMutation.data?.error}
                        </Typography>
                      ) : (
                        <Button
                          id="submit-categorization"
                          variant="contained"
                          disabled={
                            !subcategorySelection.value ||
                            !vendorCategorizationMutation.isIdle
                          }
                          onClick={onSubmitCategorization}
                        >
                          {vendorCategorizationMutation.isLoading
                            ? "Loading..."
                            : vendorCategorizationMutation.isSuccess
                              ? "Submitted!"
                              : "Propose Rule"}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}

      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h3">Category Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table sx={{ minWidth: 650 }} size="small">
              <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "80%" }} />
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    JoroCategory:
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.JoroSubcategory?.JoroCategory.joroCategory}{" "}
                    (id: {transaction?.JoroSubcategory?.JoroCategory.id})
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    TransactionType:
                  </TableCell>
                  <TableCell align="left">
                    {
                      transaction?.JoroSubcategory?.TransactionType
                        .transactionType
                    }{" "}
                    (id: {transaction?.JoroSubcategory?.TransactionType.id})
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    JoroSubcategory:
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.JoroSubcategory?.joroSubcategory} (id:{" "}
                    {transaction?.JoroSubcategory?.id})
                  </TableCell>
                </TableRow>
                <TableRow sx={{ "th, td": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    PlaidCategory:
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.plaidCategoryId}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h3">Emissions Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table sx={{ minWidth: 650 }} size="small">
              <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "80%" }} />
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Split By
                  </TableCell>
                  <TableCell align="left">{transaction?.splitBy}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Emissions
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.kgCO2e} kgCO<sub>2</sub>e
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Amount
                  </TableCell>
                  <TableCell align="left">${transaction?.amount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    ...(!transaction?.offsetKgCO2e &&
                    !transaction?.originalKgCO2e
                      ? { "th, td": { border: 0 } }
                      : {}),
                  }}
                >
                  <TableCell component="th" scope="row">
                    Subcategory weight
                  </TableCell>
                  <TableCell align="left">
                    {transaction?.subcategoryKgCO2ePerDollar} kg CO<sub>2</sub>e
                    per dollar
                  </TableCell>
                </TableRow>
                {transaction?.offsetKgCO2e && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Offset kg CO<sub>2</sub>e
                    </TableCell>
                    <TableCell align="left">
                      {transaction?.offsetKgCO2e || 0}
                    </TableCell>
                  </TableRow>
                )}
                {transaction?.originalKgCO2e && (
                  <TableRow sx={{ "th, td": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Original kg CO<sub>2</sub>e
                    </TableCell>
                    <TableCell align="left">
                      {transaction?.originalKgCO2e}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* <Grid item xs={12}>
        <Accordion
          expanded={expanded === MANUALLY_SET_EMISSIONS_FORM}
          onChange={handleChange(MANUALLY_SET_EMISSIONS_FORM)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h3">Manually Assign Emissions</Typography>
          </AccordionSummary>
          <AccordionDetails>To be filled in</AccordionDetails>
        </Accordion>
      </Grid> */}

      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h3">All Transaction Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>{JSON.stringify(transaction, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default Transaction;
