import { makeApiRequest } from "../components/utils";
import { useMutation, useQuery } from "@tanstack/react-query";

const getVendors = async (props) => {
  const searchQuery = new URLSearchParams(props);
  const hasQuery = searchQuery.toString() !== "";

  return makeApiRequest({
    endpoint: `/api/vendors${hasQuery ? `?${searchQuery.toString()}` : ""}`,
    method: "GET",
  });
};

export const useVendors = (props) => {
  return useQuery(
    [
      "VENDORS",
      props.search,
      props.limit,
      props.tags?.join(",") ?? "",
    ],
    () => getVendors(props),
    {
      cacheTime: 0,
      enabled: props.enabled ?? true,
    }
  );
};

const getVendor = async (vendorId) => {
  return makeApiRequest({
    endpoint: `/api/vendors/${vendorId}`,
    method: "GET",
  });
};

export const useVendorMutation = () => {
  return useMutation(async ({ vendorId, update }) => {
    return makeApiRequest({
      endpoint: `/api/vendors${vendorId ? `/${vendorId}` : ""}`,
      method: "PUT",
      body: update,
      json: true,
    });
  });
};

export const useVendorMemoTesterTrigger = (vendorId) => {
  return useMutation(async () => {
    return makeApiRequest({
      endpoint: `/api/vendors/${vendorId}/enhance`,
      method: "POST",
    });
  });
};

export const useVendorMemoTester = (vendorId, requestId, options = {}) => {
  return useQuery(
    ["VENDOR_MEMO_TESTER", vendorId, requestId],
    () =>
      makeApiRequest({
        endpoint: `/api/vendors/${vendorId}/enhance/${requestId}`,
        method: "GET",
      }),
    {
      enabled: !!requestId && !!vendorId,
      cacheTime: 0,
      retryDelay: 1000,
      retry: 100,
      ...options,
    }
  );
};

export const useUpdateVendorEnhancements = (vendorId) => {
  return useMutation(async (update) => {
    return makeApiRequest({
      endpoint: `/api/vendors/${vendorId}/enhance`,
      method: "PUT",
      body: update,
      json: true,
    });
  });
};

export const useVendor = (id, options = {}) =>
  useQuery(["VENDOR", id], () => getVendor(id), {
    cacheTime: 0,
    ...options,
  });
