import React, { useState } from "react";
import { ImageUpload } from "../ImageUpload";
import {
  Box,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import { resolveUrl } from "./utils";

export const ImageUploader = () => {
  const [imageURL, setImageURL] = useState("");
  const [imgixOptions, setImgixOptions] = useState({
    auto: "format",
    fit: "crop",
  });

  const onChangeImgixOptions = (option) => (e) => {
    setImgixOptions({ ...imgixOptions, [option]: e.target.value });
  };

  // select all text on click and copy to clipboard
  const onCopyInput = (id) => () => {
    const input = document.getElementById(id);
    if (!input) {
      return;
    }
    input.focus();
    input.select();
    // copy to clipboard
    document.execCommand("copy");
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ pt: 4, mx: 4, flex: 1 }}>
        <h3>Image Uploader</h3>
        <ImageUpload
          image={imageURL}
          onChange={({ target }) => setImageURL(target.value)}
        />
        {imageURL && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              my: 4,
            }}
          >
            <TextField
              sx={{ width: "100%" }}
              key={imageURL}
              type="text"
              value={imageURL}
              id="image-url"
            />
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={onCopyInput("image-url")}
            >
              Copy
            </Button>
          </Box>
        )}
      </Box>
      <Box sx={{ minWidth: "500px", width: "50%", flex: 0, mr: 4, pt: 4 }}>
        {imageURL && (
          <>
            <h3>Imgix (Optimized)</h3>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 4,
              }}
            >
              <TextField
                sx={{ width: "100%" }}
                key={JSON.stringify(imgixOptions)}
                type="text"
                value={resolveUrl(imageURL, imgixOptions)}
                id="imgix-url"
              />
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                onClick={onCopyInput("imgix-url")}
              >
                Copy
              </Button>
            </Box>

            <Box>
              <Box>
                <label>Fit</label>
                <Select
                  name="fit"
                  onChange={onChangeImgixOptions("fit")}
                  value={imgixOptions.fit}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="clamp">Clamp</MenuItem>
                  <MenuItem value="clip">Clip</MenuItem>
                  <MenuItem value="crop">Crop</MenuItem>
                  <MenuItem value="facearea">Facearea</MenuItem>
                  <MenuItem value="fill">Fill</MenuItem>
                  <MenuItem value="fillmax">Fillmax</MenuItem>
                  <MenuItem value="max">Max</MenuItem>
                  <MenuItem value="min">Min</MenuItem>
                  <MenuItem value="scale">Scale</MenuItem>
                </Select>
                <label>Width</label>
                <TextField
                  sx={{ width: "100%" }}
                  type="text"
                  value={imgixOptions.width}
                  onChange={onChangeImgixOptions("width")}
                />
                <label>Height</label>
                <TextField
                  sx={{ width: "100%" }}
                  type="text"
                  value={imgixOptions.height}
                  onChange={onChangeImgixOptions("height")}
                />
                <label>Auto format for web</label>
                <Checkbox
                  onChange={() => {
                    onChangeImgixOptions("auto")({
                      target: {
                        value: imgixOptions.auto ? null : "format",
                      },
                    });
                  }}
                  checked={imgixOptions.auto === "format"}
                />
                <br />
                <a
                  href="https://docs.imgix.com/apis/rendering"
                  target="_blank"
                  rel="noreferrer"
                >
                  See all options
                </a>
              </Box>

              <Box mt={2}>
                <h4>Preview</h4>
                <Box
                  as="img"
                  key={JSON.stringify(imgixOptions)}
                  sx={{ maxWidth: "100%" }}
                  src={resolveUrl(imageURL, imgixOptions)}
                  alt="imgix preview"
                />
              </Box>

              <details>
                <summary>Usage details</summary>
                <p>
                  You will not need to use imgix in application that
                  automatically configure these urls. The application, and the
                  web-tools automatically resize images using these
                  configurations, but this can be super beneficial for things
                  like blog post, or the website.
                </p>
              </details>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
