import { useMutation, useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "components/utils";

export const useGenieDataSearch = ({ search, filter }) => {
  return useQuery(
    ["genieSearch", { search, filter }],
    () => {
      const searchParams = new URLSearchParams({
        query: search,
        filter,
      });
      return makeApiRequest({
        endpoint: `/api/genie/search?${searchParams.toString()}`,
        method: "GET",
      });
    },
    {
      enabled: !!search,
    }
  );
};

export const useGenieDataMutation = () => {
  return useMutation((body) => {
    return makeApiRequest({
      endpoint: "/api/genie/data",
      method: "POST",
      json: true,
      body,
    });
  });
};
