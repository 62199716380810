import React from "react";
import { Box, TextField } from "@mui/material";
import { COLORS } from "../../constants/colors";

export const SecondarySidebar = ({
  children,
  backgroundColor = COLORS.WHITE,
}) => (
  <Box
    sx={{
      position: "sticky",
      top: 0,
      padding: "4px",
      display: "flex",
      height: "100vh",
      minWidth: "330px",
      overflowY: "hidden",
      flexDirection: "column",
      width: "20vw",
    }}
  >
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor,
        borderRadius: "4px",
        position: "relative",
        overflowY: "scroll",
        boxShadow: `inset 0px 0px 0px 1px ${COLORS.LIGHT_GRAY}`,
      }}
      pb={2}
      px={2}
    >
      {children}
    </Box>
  </Box>
);

export const SearchSidebar = ({
  children,
  backgroundColor = COLORS.WHITE,
  defaultSearch = "",
  onChange,
  asideContent,
  belowContent,
  placeholder = "Search",
}) => (
  <SecondarySidebar backgroundColor={backgroundColor}>
    <Box
      py={2}
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor,
        zIndex: "10",
      }}
    >
      <Box display="flex" pb={2}>
        <TextField
          defaultValue={defaultSearch}
          onChange={onChange}
          sx={{ width: "100%" }}
          label={placeholder}
          size="small"
          variant="outlined"
          name="search"
        />
        {asideContent && <Box pl={2}>{asideContent}</Box>}
      </Box>
      {belowContent && (
        <Box flexDirection="row" display="flex" alignItems="center">
          {belowContent}
        </Box>
      )}
    </Box>
    <Box display="flex" flexDirection="column" as="ul">
      {children}
    </Box>
  </SecondarySidebar>
);
