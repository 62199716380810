import React from "react";
import { Box } from "@mui/material";
import { COLORS } from "../constants/colors";
import { useUploadImage } from "../hooks/useUploadImage";

export const ImagePreviewInput = ({
  src,
  alt,
  name,
  onSrcChange,
  imageRatio = "16:9",
  ...boxProps
}) => {
  const { mutateAsync: uploadImage } = useUploadImage();
  const ratio = imageRatio.split(":").reduce((a, b) => b / a);
  const onImageUpload = async (e) => {
    const file = e.target.files[0];
    const imageType = e.target.name;
    const resp = await uploadImage({ imageType, image: file });
    onSrcChange({
      target: {
        name,
        value: resp.assetUrl,
      },
    });
  };

  return (
    <Box
      mb={4}
      {...boxProps}
      sx={{
        borderRadius: "8px",
        border: `1px solid ${COLORS.WARM_GRAY}`,
        overflow: "hidden",
        position: "relative",
        "::after": {
          display: "block",
          content: '""',
          paddingBottom: `${ratio * 100}%`,
        },
        width: "100%",
        ...boxProps.sx,
      }}
    >
      <Box
        as="input"
        type="file"
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          opacity: "0",
          cursor: "pointer",
          zIndex: "2",
        }}
        onChange={onImageUpload}
        accept="image/jpeg"
        name="imageURL"
      />
      <Box
        as="img"
        src={src}
        alt={alt}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: "1",
        }}
      />
    </Box>
  );
};
