import { makeApiRequest } from "../components/utils";
import { useQuery } from "@tanstack/react-query";

const getCarbonizerConstants = async () => {
  return makeApiRequest({
    endpoint: "/api/carbonizer_constants/defaults",
    method: "GET",
  });
};

const validCarbonizerConstantKeys = [
  "peopleInHome",
  "roomsInHome",
  "weeklyRedMeatMeals",
  "weeklyWhiteMeatMeals",
  "weeklyDairyEggMeals",
  "longHaulFlights",
  "medHaulFlights",
  "shortHaulFlights",
  "commuteMode",
  "commuteDistance",
  "solarEnergyPercent",
  "cleanEnergyPercent",
  "weeklyGoodsAndServices",
];

const prepConstants = (constants, countryCode) => {
  const regexPattern = new RegExp(
    `^(\\w+)_default(?:_${countryCode})?(?!_[A-Z]{2})$`
  );

  const preparedCarbonizerConstants =
    constants
      ?.filter((constant) => constant.key.match(regexPattern))
      ?.map((constant) => ({ ...constant, key: constant.key.replace(regexPattern, "$1") }))
      ?.filter((constant) => validCarbonizerConstantKeys.includes(constant.key))
      ?.reduce((acc, constant) => {
        return {
          ...acc,
          [constant.key]: constant.value,
        };
      }, {}) ?? {};

  return preparedCarbonizerConstants;
};

export const useCarbonizerConstants = (countryCode, setAllState) => {
  const { data, error, isLoading } = useQuery(
    ["CARBONIZER_CONSTANTS"],
    () => getCarbonizerConstants(),
    {
      onSuccess: (data) => {
        if (data?.carbonizerConstants && countryCode) {
          // eslint-disable-next-line no-unused-vars
          const carbonizerConstants = prepConstants(
            data?.carbonizerConstants,
            countryCode
          );

          setAllState((prevState) => ({
            ...prevState,
            user: {
              ...prevState.user,
              ...carbonizerConstants,
            },
          }));
        }
      },
    }
  );

  return {
    carbonizerConstants: prepConstants(data?.carbonizerConstants, countryCode),
    isLoadingCarbonizerConstants: isLoading,
    carbonizerConstantsError: error,
  };
};
