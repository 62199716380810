/**
 * Colors defined in Jicama Design System
 * https://www.figma.com/file/1P2VWxxuecXoHUPZptsY21/Jicama-Design-System?node-id=2554%3A12708
 */

// PRIMARY
export const COLORS = {
  COMMONS_BLUE: "#0000A0",
  BLACK: "#000000",
  GRAY: "#6A6860",
  WARM_GRAY: "#B7B2A4",
  LIGHT_GRAY: "#E9E7E3",
  BEIGE: "#F9F8F8",
  WHITE: "#FFFFFF",
  PERIWINKLE: "#7A8EFF",

  // CATEGORY
  TRAVEL: "#8B98A2",
  FOOD: "#F5793A",
  ELECTRICITY: "#F2B80D",
  SHOPPING: "#E59FFE",
  FINANCE: "#929C02",
  OFFSET: "#7A8EFF",
  EMISSION: "#0000A0",

  // SYSTEM
  SUCCESS: "#7D9C14",
  SUCCESS_LIGHT: "#CAE79A",
  ERROR: "#EB3323",
  ERROR_LIGHT: "#F99B93",
  ERROR_BG: "#FCE3E1",
};
