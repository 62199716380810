import React, { useState } from "react";
import { useGenieDataSearch } from "./hooks";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Input,
  Link,
  Typography,
} from "@mui/material";

export const GenieSearch = () => {
  const [query, setQuery] = useState("");
  const [urlFilter, setFilters] = useState("");
  const {
    data: searchResults,
    isLoading,
    isError,
    error,
  } = useGenieDataSearch({ search: query, filter: urlFilter });

  return (
    <Box minWidth="100%">
      <Box px={2} py={2} minWidth="100%">
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            const searchQueryInput = document.getElementById("search");
            const filters = document.getElementById("filter");
            if (searchQueryInput instanceof HTMLInputElement) {
              setQuery(searchQueryInput.value);
            }
            if (filters instanceof HTMLInputElement) {
              setFilters(filters.value);
            }
          }}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          rowGap={2}
        >
          <Input
            placeholder="Question"
            id="search"
            type="text"
            defaultValue={query}
            fullWidth
          />
          <Input
            placeholder="Url Filter ( comma separated )"
            id="filter"
            type="text"
            defaultValue={query}
            fullWidth
          />
          <Button type="submit" variant="contained">
            Search
          </Button>
        </Box>
      </Box>
      {(() => {
        if (isLoading && query) {
          return (
            <Box px={2} py={2}>
              <CircularProgress />
            </Box>
          );
        }
        if (isError) {
          return (
            <Box px={2} py={2}>
              <p>Error: {error?.message}</p>
            </Box>
          );
        }
        return (
          <Box px={2} py={2}>
            <Typography variant="h6">
              Results {searchResults?.results.length ?? 0}
            </Typography>
            {searchResults && (
              <Box
                component="ul"
                display="flex"
                rowGap={2}
                flexDirection="column"
              >
                {searchResults.results.map((result) => (
                  <Box key={result.id} component="li">
                    <Card>
                      <CardContent>
                        <Typography variant="caption">
                          <small>
                            Similarity: {result.similarity.toFixed(3)}
                          </small>
                        </Typography>

                        <Typography variant="body1">{result.text}</Typography>

                        <Link href={result.citation}>
                          <Typography variant="caption">
                            {result.citation}
                          </Typography>
                        </Link>
                      </CardContent>
                    </Card>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        );
      })()}
    </Box>
  );
};
