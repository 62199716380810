import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "../components/utils";

export const useCreateVendorOfferMutation = (vendorId) => {
  return useMutation(async (data) => {
    return makeApiRequest({
      endpoint: `/api/vendors/${vendorId}/offers`,
      method: "POST",
      body: data,
      json: true,
    });
  });
};

export const useUpdateVendorOfferMutation = (vendorId) => {
  return useMutation(async ({ id, changes }) => {
    return makeApiRequest({
      endpoint: `/api/vendors/${vendorId}/offers/${id}`,
      method: "PUT",
      body: changes,
      json: true,
    });
  });
};
