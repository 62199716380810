import React from "react";
import { makeApiRequest } from "../components/utils";
import { useQuery } from "@tanstack/react-query";

export const useSubcategories = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["subcategories"],
    queryFn: () =>
      makeApiRequest({
        endpoint: "/api/subcategories",
        method: "GET",
      }),
  });

  return {
    isLoading,
    error,
    subcategoryOptions: React.useMemo(
      () =>
        data?.subcategories?.map((subcategory) => ({
          label: `${subcategory.joroSubcategory} - ${subcategory?.TransactionType?.transactionType} (id: ${subcategory.id})`,
          value: subcategory.id,
        })) || [],
      [data]
    ),
    data,
  };
};
