import React from "react";
import { Box } from "@mui/material";

export const FormContainer = ({ width = "40vw", children }) => {
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        padding: "4px",
        display: "flex",
        flex: 1,
        minWidth: "330px",
        overflowY: "hidden",
        flexDirection: "column",
        width,
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          overflowY: "scroll",
        }}
        pb={2}
        px={2}
      >
        <Box
          sx={{
            position: "sticky",
            top: 1,
            zIndex: 1,
          }}
        >
          <Box display="flex" flexDirection="column" py={2}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
