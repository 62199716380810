import React, { useMemo, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { COLORS } from "../../constants/colors";
import {
  Card,
  CircularProgress,
  Divider,
  Typography,
  Checkbox,
} from "@mui/material";
import {
  useUpdateVendorEnhancements,
  useVendorMemoTester,
} from "../../hooks/useVendors";
import { DataGrid } from "@mui/x-data-grid";
import { SidebarModule } from "./SidebarModule";

export const MemoTester = ({ requestId, vendorId, closeModal, vendorName }) => {
  const [shouldUpdateVendor, setShouldUpdateVendor] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [vendorsToUpdate, setVendorsToUpdate] = useState([]);
  const { data: memoTesterData, isRefetching } = useVendorMemoTester(
    vendorId,
    requestId
  );
  const { mutateAsync: acceptVendorEnhancements, isLoading } =
    useUpdateVendorEnhancements(vendorId);

  const vendorSummary = useMemo(() => {
    return (
      memoTesterData?.results.vendor_summary
        .map((vendor) => ({
          ...vendor,
          id: parseInt(vendor.vendor.split(" ")[0], 10),
        }))
        .filter((v) => !v.vendor.startsWith(vendorId + ""))
        .filter((v) => !v.vendor.startsWith("None"))
        .reduce((accum, v) => {
          const existing = accum.find((a) => a.vendor === v.vendor);
          if (existing) {
            return accum;
          }
          accum.push(v);
          return accum;
        }, []) ?? []
    );
  }, [memoTesterData, vendorId]);
  const pattern = memoTesterData?.results.pattern;

  const onUpdateVendors = useCallback(async () => {
    const vendorUpdate = shouldUpdateVendor ? { matchRegex: pattern } : {};

    await acceptVendorEnhancements({
      cleanVendorDetails: vendorUpdate,
      correctedVendorIds: vendorsToUpdate ?? [],
    });
    setUpdated(true);
  }, [acceptVendorEnhancements, pattern, shouldUpdateVendor, vendorsToUpdate]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100vw", height: "100vh" }}
      onClick={() => closeModal()}
      data-testid="memo-tester-modal"
    >
      {(() => {
        if (updated) {
          return (
            <Card
              onClick={(e) => e.stopPropagation()}
              sx={{
                height: "50vh",
                width: "50vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h4" sx={{ pt: 2, textAlign: "center" }}>
                Successfully updated vendors.
              </Typography>
            </Card>
          );
        }

        if (!memoTesterData) {
          return (
            <Card
              onClick={(e) => e.stopPropagation()}
              sx={{
                height: "50vh",
                width: "50vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
              <Typography variant="h4" sx={{ pt: 2, textAlign: "center" }}>
                Testing memos, this may take a while. <br />
                Please do not exit the modal.
              </Typography>
            </Card>
          );
        }

        if (!isRefetching && !memoTesterData) {
          return (
            <Card
              onClick={(e) => e.stopPropagation()}
              sx={{
                height: "50vh",
                width: "50vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h4" sx={{ pt: 2, textAlign: "center" }}>
                Unable to get test results. Please close the modal and try
                again.
              </Typography>
            </Card>
          );
        }

        return (
          <Card
            onClick={(e) => e.stopPropagation()}
            sx={{
              height: "80vh",
              width: "90vw",
              overflowY: "hidden",
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              height="100%"
            >
              <Box
                padding={6}
                flexDirection="column"
                display="flex"
                overflowY="scroll"
                height="100%"
                maxWidth="60%"
                flex="1"
              >
                <Typography variant="h3" fontWeight="bold" sx={{ pb: 1 }}>
                  Results &quot;{vendorName}&quot;
                </Typography>
                <Card
                  sx={{
                    borderWidth: 1,
                    borderColor: COLORS.LIGHT_GRAY,
                    borderStyle: "solid",
                  }}
                >
                  <Box
                    px={3}
                    py={2}
                    display="flex"
                    alignItems="center"
                    columnGap={2}
                  >
                    <Checkbox
                      value={shouldUpdateVendor}
                      onChange={() =>
                        setShouldUpdateVendor(!shouldUpdateVendor)
                      }
                    />
                    <Box>
                      <Typography variant="h5" fontWeight="bold">
                        Update vendor with this pattern
                      </Typography>
                      <Typography variant="caption">
                        Pattern: <b>{memoTesterData?.results.pattern}</b>
                      </Typography>
                    </Box>
                  </Box>
                </Card>

                <Divider sx={{ mt: 3 }} />

                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ pt: 3, pb: 2 }}
                >
                  Select vendors to point towards this vendor.
                </Typography>
                <DataGrid
                  checkboxSelection
                  columns={[
                    {
                      headerName: "Vendor",
                      field: "vendor",
                      width: 400,
                    },
                    {
                      headerName: "Subcategory",
                      field: "subcategory",
                      width: 400,
                    },
                    {
                      headerName: "Txn count",
                      field: "transaction_count",
                      width: 150,
                    },
                  ]}
                  rows={vendorSummary}
                  onRowSelectionModelChange={(selection) => {
                    // eslint-disable-next-line no-console
                    setVendorsToUpdate(selection);
                  }}
                />
              </Box>
              <Box
                flexDirection="column"
                display="flex"
                padding={6}
                backgroundColor={COLORS.LIGHT_GRAY}
              >
                <Button
                  variant="contained"
                  disabled={
                    (!shouldUpdateVendor && !vendorsToUpdate.length) ||
                    isLoading
                  }
                  onClick={async () => {
                    await onUpdateVendors();
                  }}
                  sx={{ mb: 3 }}
                >
                  {isLoading ? "Updating..." : "Accept Enhancements"}
                </Button>
                <SidebarModule title="Cleaned Memos" isExpanded={true}>
                  <Box flexDirection="column" display="flex">
                    {memoTesterData?.results.cleaned_up_memos.map((memo, i) => {
                      return <Typography key={i}>{memo}</Typography>;
                    })}
                  </Box>
                </SidebarModule>
              </Box>
            </Box>
          </Card>
        );
      })()}
    </Box>
  );
};
