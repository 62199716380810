export const ContentTags = {
  TRAVEL: "travel",
  FINANCE: "finance",
  FOOD: "food",
  OFFSETS: "offsets",
  SHOPPING: "shopping",
  HOME: "home",
  OTHER: "other",
  BEST_BRANDS: "bestBrands",
  BUYING_GUIDES: "buyingGuides",
};

// Map to displayable names for each tag
export const ContentTagDisplayNames = {
  travel: "Travel",
  finance: "Finance",
  food: "Food",
  offsets: "Offsets",
  shopping: "Shopping",
  home: "Home",
  other: "Other",
  bestBrands: "Best Brands",
  buyingGuides: "Buying Guides",
};
