import React from "react";
import { Box } from "@mui/material";

export const H1 = (boxProps) => <Box component="h1" {...boxProps} />;
export const H2 = (boxProps) => <Box component="h2" {...boxProps} />;
export const H3 = (boxProps) => <Box component="h3" {...boxProps} />;
export const H4 = (boxProps) => <Box component="h4" {...boxProps} />;
export const H5 = (boxProps) => <Box component="h5" {...boxProps} />;
export const H6 = (boxProps) => <Box component="h6" {...boxProps} />;
export const P = (boxProps) => <Box component="p" {...boxProps} />;
export const Span = (boxProps) => <Box component="span" {...boxProps} />;
export const Small = (boxProps) => <Box component="small" {...boxProps} />;
export const Strong = (boxProps) => <Box component="strong" {...boxProps} />;
export const Em = (boxProps) => <Box component="em" {...boxProps} />;
export const A = (boxProps) => <Box component="a" {...boxProps} />;
