import React, { useState } from "react";
import { Alert, Button, TextField } from "@mui/material";
import { COLORS } from "constants/colors";

export const JsonContainer = ({ state, setState }) => {
  const formattedState = JSON.stringify(state, null, 2);
  const [text, setText] = useState(formattedState);
  const [error, setError] = useState(false);

  const parseAndSetState = () => {
    let parsedState;
    try {
      parsedState = JSON.parse(text);
    } catch (e) {
      setError(e);
    }
    setState(parsedState);
  };
  return (
    <>
      {error && <Alert>{error}</Alert>}
      <TextField
        multiline={true}
        minRows={15}
        sx={{
          color: COLORS.BLACK,
          marginTop: "30px",
          minHeight: "300px"
        }}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <Button type={"submit"} onClick={parseAndSetState}>Sync</Button>
    </>
  );
};
